<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<side-menu v-if="!isLoading"
		           v-show="showMenu"
		           :id-museum="encrypted"
		           @close-menu="showMenu = false"/>
		
		<nav v-if="!isLoading">
			<h1>{{ sideMenu && sideMenu.locale ? sideMenu.locale.languageText : $t('language', $i18n.locale) }}</h1>
			<div v-if="museum.menuAvailable && mode !== 'by-halls'" class="menu" @click="showMenu = true; setFullScreen()">
				<img class="menu-icon" src="@/assets/icons/stayrelax_black.png" alt="">
			</div>
		</nav>
		
		<main v-if="!isLoading && languages">
			<div class="languages">
				<article v-for="language in languages" :key="language.idLanguage"
				         :class="{ 'active': isActiveLanguage(language) }"
				         @click="changeLanguage(language)">
					<p>{{ language.originalTitle }}</p>
				</article>
			</div>
		</main>
		
		<footer>
			<button class="button-white" @click="goToOptions()">
				<i :style="backGroundColor" class="mm-icon mm-icon-arrow-back big"/>
			</button>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import SideMenu from "@/components/SideMenu";
import {setFullScreen} from "@/store/helpers";

export default {
	name: "Languages",
	
	components: {
		SideMenu,
		Loading
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			showMenu: false,
			mode: localStorage.getItem('mode')
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			sideMenu: state => state.museum.sideMenu,
			visitv2: state => state.app.visitv2,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('language');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		languages() {
			return this.museum ? this.museum.languages : null;
		},
		
		cssVars() {
			return {
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		},
		backGroundColor(){
				return{
				'color': this.museum.code ==='MPICASSOM' && this.keyboardCustomization ? this.keyboardCustomization.mainButtonsColor : 'black',
			};

		},
	},
	
	created() {
		debug.log("[Languages] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('museum', ['changeSideMenuLocale']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		changeLanguage(language) {
			setFullScreen();
			this.$root.$i18n.locale = language.code.substr(0, 2);
			const languagesV2 = JSON.parse(localStorage.getItem("languagesV2"));
			const visitv2Storage = JSON.parse(localStorage.getItem("visitv2"));
			const itemLanguage = languagesV2.find(e => e.code === language.code);
			visitv2Storage.idLanguage = itemLanguage?.id;
			visitv2Storage.languageCode = itemLanguage?.code;
			const visitv2Copy = {...this.visitv2};
			visitv2Copy.idLanguage = itemLanguage?.id;
			visitv2Copy.languageCode = itemLanguage?.code;

			this.$store.commit('app/' + mutationTypes.SET_VISITV2, visitv2Copy, { root: true });
			localStorage.setItem("visitv2", JSON.stringify(visitv2Storage));
			this.changeSideMenuLocale();
			
			debug.log("[changeLanguage] Language changed to " + language.originalTitle + " (" + language.code + ")");
			localStorage.setItem('language', this.$root.$i18n.locale);
			
		},
		
		isActiveLanguage(language) {
			let cleanLocalization = language.code.replace(/[|&;$%@"<>_\-()+,]/g, "");
			let cleanShortLocalization = cleanLocalization.substr(0, 2);
			let cleanLocale = this.$i18n.locale.replace(/[|&;$%@"<>_\-()+,]/g, "");
			
			return cleanLocale === cleanLocalization || cleanLocale === cleanShortLocalization;
		},
		
		goToOptions() {
			setFullScreen();
			this.$router.push({ name: "Options", params: { idMuseum: this.encrypted } });
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	
	nav {
		position: fixed;
		top: 0;
		height: 75px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: white;
		border-bottom: 1px solid #cfcfd0;
		
		h1 {
			font-weight: normal;
			font-size: 22px;
			padding: 0 20px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		
		.menu {
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 1px solid #cfcfd0;
			padding: 5px 20px;
			
			.menu-icon {
				width: 40px;
				height: auto;
			}
		}
	}
	
	main {
		padding: 90px 20px;
		width: 100%;
		height: 100%;
		background-color: white;
		overflow-y: scroll;
		
		.languages {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			
			article {
				width: calc(50% - 10px);
				background-color: white;
				box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
				margin-bottom: 20px;
				height: 120px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: uppercase;
				
				&:nth-child(odd) {
					margin-right: 20px;
				}
				
				&.active {
					background-color: var(--main-buttons-color);
					color: var(--main-buttons-text-color);
				}
			}
		}
	}
	
	footer {
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		position: fixed;
		bottom: 0;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.button-white {
			border: none;
			background-color: white;
			height: 100%;
			color: black;
			
			img {
				width: 15px;
				height: auto;
			}
		}
	}
}
</style>
